import React, {useRef, useEffect} from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Article from '../components/Article'
// import postscribe from 'postscribe'

const Collection = ({title, html}) => {
  return (
    <section>
      <h2>{title}</h2>
      <div dangerouslySetInnerHTML={{__html: html}}/>
    </section>
  )
}
const NewslettersPage = ({data}) => {
  const {frontmatter: {title, description}, html} = data.file.childMarkdownRemark;
  return (
    <Layout>
      <SEO title={title} description={description} />

      <Container>
        <Article>
          <h1>{title}</h1>
          {data.allMailchimpFolder.edges.map(({node}) => 
            <Collection key={node.id} {...node} />)}
        </Article>
      </Container>
    </Layout>
  )
}

export default NewslettersPage;

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "pages/newsletters.md"}) {
      childMarkdownRemark {
        frontmatter { title description }
        html
      }
    }

    allMailchimpFolder(sort: {fields: title, order: DESC}) {
      edges {
        node {
          id
          title
          html
        }
      }
    }
  }
`;
